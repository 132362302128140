// If you want to override variables do it here

// Import styles








// If you want to add something do it here



.cdk-overlay-container {
  z-index: 1050 !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #fff;
}

.img {
  width: 153px;
  height: 153px;
  border-radius: 25%;
  border: 1px solid #00000014;
}
